import { BrowserRouter } from 'react-router-dom';

// routes
import AllRoutes from '.';

const Routes = () => {

    let langDir = (localStorage.getItem('lang') || 'ar') === 'ar' ? 'rtl' : 'ltr';

    return (
        <div dir={langDir}>
            <BrowserRouter>
                <AllRoutes />
            </BrowserRouter>
        </div>
    );
};

export default Routes;
